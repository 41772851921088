// Layers imports
import { usePointsLayer } from 'context/maps/layers/points';
import { Isochrone } from './isochrone';
import { HeatmapLayer } from './heatmap';
import { Buildings } from './buildings';

// Context top
import { useCategory } from 'context/filters/category';

// Third-party imports
import { useControl } from 'react-map-gl';
import { MapboxOverlay } from '@deck.gl/mapbox/typed';
import type { DeckProps } from '@deck.gl/core/typed';

const DeckGLOverlay = (props: DeckProps) => {
  const deck = useControl<any>(() => new MapboxOverlay(props));
  deck.setProps(props);
  return null;
}

export const Layers = () => {
	const { pointsLayer } = usePointsLayer();
	const { categoryId } = useCategory();

	return (
		<>
			<Isochrone/>
			<Buildings/>
			{categoryId === 2 && <HeatmapLayer/>}
			{categoryId === 1 && <DeckGLOverlay layers={pointsLayer}/>}
		</>
	)
}