// React imports
import { useState, useRef, useEffect, useContext, createContext } from 'react';

// Context imports
import { useGeo } from '../../filters/geo';

const MapboxContext: React.Context<any> = createContext(null);

export const useMapbox = () => useContext(MapboxContext);

export const MapboxProvider = ({children}: any) => {
  const { viewport } = useGeo();
  const { longitude, latitude, zoom } = viewport;

  const mapRef = useRef<any>();
  const pdfMapRef = useRef<any>();

  const [ currentBasemap, setCurrentBasemap ] = useState("mapbox://styles/generativa/clhqor7c101lh01pe52myfwik");
  const [ chatCoords, setChatCoords ] = useState<any>(null);

  useEffect(() => {
    const center = [ longitude, latitude ];

    mapRef.current?.flyTo({
      center: center,
      zoom: zoom,
      duration: 3000, 
      essential: true,
    });

    pdfMapRef.current?.flyTo({
      center: center,
      duration: 3000, 
      essential: true,
    });
  }, [ viewport ]);
  
  return (
    <MapboxContext.Provider value={{ 
      mapRef, pdfMapRef, 
      currentBasemap, setCurrentBasemap,
      chatCoords, setChatCoords
    }}>
      {children}
    </MapboxContext.Provider>
  )
}

MapboxContext.displayName="MapboxContext";