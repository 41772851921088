// App imports
import './styles.scss';

export const Tools = () => {
	return (
		<div className="graphic-tools">
			<div className="boundary-controller">
				<img src="static/main/pdf/maps/icons/pencil.svg" alt="pencil" width="100%"/>
			</div>
			<div className="boundary-controller">
				<img src="static/main/pdf/maps/icons/polygon.svg" alt="polygon" width="100%"/>
			</div>
			<div className="boundary-controller">
				<img src="static/main/pdf/maps/icons/circle.svg" alt="circle" width="100%"/>
			</div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	)
}

Tools.displayName="Tools";