// React imports
import { useCallback, useContext, createContext } from 'react';

// Context imports
import { useGeo } from 'context/filters/geo';
import { useIsochroneApi } from 'context/api/isochrone';
import { useMapbox} from 'context/maps/mapbox';

const MapEventsContext: React.Context<any> = createContext(null);

export const useMapEvents = () => useContext(MapEventsContext);

export const MapEventsProvider = ({children}: any) => {
  const { initialMarker, setInitialMarker } = useIsochroneApi();
  const { setViewport } = useGeo();
  const { mapRef, setChatCoords } = useMapbox();

  const onDblClick = useCallback((event: any) => {
    event.preventDefault();
    setInitialMarker(false);
    const { lat, lng } = event.lngLat;
    setViewport((prev: any) => ({...prev, longitude: lng, latitude: lat }));
  }, [ setInitialMarker, setViewport ]);

  const onContextMenu = (e: any) => {
    const map = mapRef?.current?.getMap();

    if (map.isMoving() || map.isZooming() || map.isRotating() || initialMarker) {
      return;
    }
    else {
      setChatCoords((prev: any) => !prev ? e.lngLat : null)  
    }
  }
 
  return (
    <MapEventsContext.Provider value={{ onDblClick, onContextMenu }}>
      {children}
    </MapEventsContext.Provider>
  )
}

MapEventsContext.displayName="MapEventsContext";