// React imports
import { useContext, createContext } from 'react';

// Context imports
import { usePricesApi } from '../../api/imoveis/prices';
import { useAreas } from '../../filters/areas';
import { usePrices } from '../../filters/prices';
import { useDates } from '../../filters/dates';
import { usePropertyType } from '../../filters/property';
 
const PricesLimitsContext: React.Context<any> = createContext(null)

export const usePricesLimits = () => {
    return (
        useContext(PricesLimitsContext)
    )
}

export const PricesLimitsProvider = ({children}: any) => {
    const { pricesData } = usePricesApi();
    const { areaMin, areaMax } = useAreas();
    const { unitPrice, leftPosition, rightPosition } = usePrices();
    const { formatedStartDate, formatedFinalDate } = useDates();
    const { activeEquipment } = usePropertyType();

    const currentPriceString = unitPrice === "price" ? "price" : "unit_price";
    const isRelevantEquipment = ["furnished", "pool", "new", "status"].includes(activeEquipment);

    const filterPrices = pricesData?.filter((d: any) => {
        const priceInRange = leftPosition < d[currentPriceString] && d[currentPriceString] < rightPosition;
        const areaInRange = areaMin < d.processed_area && d.processed_area < areaMax;
        const equipmentActive = isRelevantEquipment ? d[activeEquipment] === 1 : true;
        const dateInRange = formatedStartDate < new Date(d.start_date) && new Date(d.start_date) < formatedFinalDate;

        return priceInRange && areaInRange && equipmentActive && dateInRange;
    });

    return (
        <PricesLimitsContext.Provider value={{ filterPrices }}>
            {children}
        </PricesLimitsContext.Provider>
    )
}

PricesLimitsContext.displayName = "PricesLimitsContext";