// App imports
import { Pin } from './pin';
import { Controllers } from './controllers';
import { Isochrone } from './isochrone';
import { Tools } from './tools';
import './styles.scss';

// Context imports
import { useMapbox } from 'context/maps/mapbox';
import { useGeo } from 'context/filters/geo';
import { useIconLayer } from 'context/maps/layers/icon';
import { useMapEvents } from 'context/maps/events';

// Third-party imports
import { DeckProps } from '@deck.gl/core/typed';
import { MapboxOverlay } from '@deck.gl/mapbox/typed';
import { Map, useControl } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const DeckGLOverlay = (props: DeckProps) => {
  const deck = useControl<any>(() => new MapboxOverlay(props));
  deck.setProps(props);
  return null;
}

export const PdfMaps = () => {
	const { pdfMapRef } = useMapbox();
	const { viewport } = useGeo();
	const { iconLayer } = useIconLayer();
	const { onDblClick } = useMapEvents();

	const layers: any = [ iconLayer ];

	return (
		<div className="pdf-maps">
			<Map
				ref={pdfMapRef}
				initialViewState={viewport}
				mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN} 
				mapStyle="mapbox://styles/mapbox/light-v10"
				onDblClick={onDblClick}
				doubleClickZoom={false}
			>
				<DeckGLOverlay 
					layers={layers} 
					glOptions={{preserveDrawingBuffer: true}}
				/>
				<Isochrone/>
				<Pin/>
				<Controllers/>
			</Map>
			<Tools/>
		</div>
	)
}

PdfMaps.displayName="PdfMaps";