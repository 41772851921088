// App imports
import { priceFormat } from 'components/utils/constants';
import { Header } from './header';
import { Mean } from './mean';
import './styles.scss';

// Context imports
import { useEquipment } from 'context/filters/equipment';

// Third party imports
import * as d3 from 'd3';

export const Table = ({ data }: any) => {
	const { rooms, setRooms, setSuites, setGarages } = useEquipment();

	const handleClick = (item: any) => {
		const [ newRooms, newSuites, newGarages ] = item.split(",");
		setRooms(newRooms)
		setSuites(newSuites)
		setGarages(newGarages)
	}
	
	const combinedCounts = 
		Object.values(data || {})
			.reduce((acc: any, dataset: any) => {
				return dataset.counts ? { ...acc, ...dataset.counts } : acc;
			}, {});

	const histCount = rooms ? data[`d${rooms}`].counts : combinedCounts;

	if (!histCount) return null;
	
	const histKeys = Object.keys(histCount);

	const sumOfCounts = d3.sum(Object.values(histCount), (item: any) => item.count);
	const sortedKeys = histKeys.sort((a: any, b: any) => histCount[b].count - histCount[a].count);
		
	return (
		<div className="right-images-wrapper">
			<table>
				<Header />
				{sortedKeys.map((item, index) => {
					const { count, mean_price, mean_area, monthly_views } = histCount[item];
					const percentage = (count / sumOfCounts) * 100;

					if (percentage <= 1) return null;

					return (
						<tr key={index} onClick={() => handleClick(item)}>
							<td><div className="table-item">{index + 1}</div></td>
							<td><div className="table-item">{item}</div></td>
							<td><div className="table-item">{Math.round(percentage)}%</div></td>
							<td><div className="table-item">{priceFormat(mean_price)}</div></td>
							<td><div className="table-item">{Math.round(mean_area)}</div></td>
							<td><div className="table-item">{Math.round(count)}</div></td>
							<td style={{ width: "30px" }}>
								<div style={{ width: "30px" }}>
									<Mean data={monthly_views} />
								</div>
							</td>
						</tr>
					);
				})}
			</table>
		</div>
	)
}

Table.displayName="Table";