// App imports
import { MapHeader } from './header';
import { SvgMap } from './svgMap';
import { Pictures } from './pictures';
import { BasemapsSelectors } from './basemaps';
import './styles.scss';

export const Wrapper = ({ children }: any) => {
	return (
		<div className="map-wrapper">
			<MapHeader/>
			<SvgMap/>
			{children}
			<BasemapsSelectors/>
			<Pictures/>
		</div>
	)
}

Wrapper.displayName="Wrapper"