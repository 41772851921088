// App imports
import './styles.scss';

// Context imports
import { useEquipment } from 'context/filters/equipment';

export const Header = () => {
	const { rooms, setRooms, setSuites, setGarages } = useEquipment();

	const onClick = () => {
		setRooms(null);
		setSuites(null);
		setGarages(null); 
	}

	const path = process.env.PUBLIC_URL + "/static/main/refresh/";
	const refreshActive = path + "white.svg";
	const refreshInactive = path + "gray.svg";
	const src = rooms === null ? refreshInactive : refreshActive;

	return (
		<div className="property-type-header">
			<div className="sidebar-sub-title">
				Principais tipologias
			</div>
			<img 
				className="refresh"
				src={src}
				alt="refresh" 
				onClick={onClick}
			/>
		</div>
	)
}

Header.displayName="Header";