// React imports
import { useState } from 'react';

// App imports
import { Wrapper } from './wrapper';
import { Controllers } from './controllers';
import { Pin } from './pin';
import { Tooltip } from './tooltip';
import { Layers } from './layers';
import { Chat } from './chat';

// Context imports
import { useMapbox } from 'context/maps/mapbox';
import { useGeo } from 'context/filters/geo';
import { useMapEvents } from 'context/maps/events';

// Third-party imports
import { Map } from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

export const Maps = () => {
	const { mapRef, currentBasemap, chatCoords, setChatCoords } = useMapbox();
	const { viewport } = useGeo();
	const { onDblClick, onContextMenu } = useMapEvents();
	
	const [ isMapLoaded, setIsMapLoaded ] = useState<any>(false);

	return (
		<Wrapper>
			<Map
				ref={mapRef}
				initialViewState={viewport}
				mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN} 
				mapStyle={currentBasemap}
				onDblClick={onDblClick}
				doubleClickZoom={false}
				onContextMenu={onContextMenu}
				onLoad={() => setIsMapLoaded(true)}
				onClick={() => setChatCoords(null)}
			>
			{isMapLoaded && 
				<>
					<Layers/>
					<Pin/>
					<Controllers/>
					<Tooltip/>
					{chatCoords && <Chat coords={chatCoords}/>}
				</>
			}
			</Map>
		</Wrapper>
			
	)
}

Maps.displayName="Maps";