// App imports
import './styles.scss';

// Context imports
import { useChat } from 'context/chat';
import { usePricesLimits } from 'context/limits/prices';

export const UserInput = ({ setResponseData, setUserQuery }: any) => {
	const { searchText, handleChange, onKeyDown, cleanSuggestions } = useChat();
	const { filterPrices } = usePricesLimits();

	if (!filterPrices) return <></>;

	const data = filterPrices.map(({ geometry, ...rest }: any) => rest);

    const sendRequest = (currentText: any) => {
    	setResponseData((prev: any) => [...prev, { sender: "user", message: currentText }])
    	setUserQuery({question: currentText, knowledgeBase: data});
    	cleanSuggestions();
	};

	return (
		<div className="chat-input-container">
			<textarea
				className="chat-input"
				placeholder="Type your message here..."
				value={searchText ? searchText : ""}
				onChange={handleChange}
				spellCheck={false}
				onKeyDown={onKeyDown}
			/>
			<button 
				className="chat-send-button" 
				onClick={() => sendRequest(searchText)}
			>
				Send
			</button>
		</div>
	)
}

UserInput.displayName="UserInput";