// App imports
import { LayersProvider } from './layers';
import { TooltipProvider } from './tooltip';
import { MapboxProvider } from './mapbox';
import { MapEventsProvider } from './events';

export const MapsProvider = ({children}: any) => {
  return (
    <MapboxProvider>
    <TooltipProvider>
    <LayersProvider>
    <MapEventsProvider>
      {children}
    </MapEventsProvider>
    </LayersProvider>
    </TooltipProvider>
    </MapboxProvider>
  )
}

MapsProvider.displayName="MapsProvider";