// Context imports
import { useIsochroneApi } from 'context/api/isochrone';

// Third party imports
import { Source, Layer } from 'react-map-gl';

export const Isochrone = () => {
	const { isochroneData } = useIsochroneApi();

	const geoJsonData: any = {
		type: 'Feature',
		geometry: {
			type: 'Polygon',
			coordinates: isochroneData?.features[0].geometry.coordinates,
		}
	}

	const isoLayer: any = {
		id: 'iso-layer',
	    type: 'fill',
	    source: 'iso-source',
	    paint: {
	      'fill-color': 'rgb(222, 112, 112)',
	      'fill-opacity': 0.2,
	    },
	};
	
	return (
		<Source 
			id="iso-source" 
			type="geojson" 
			data={geoJsonData}
		>
			<Layer {...isoLayer}/>
		</Source>
	)
}