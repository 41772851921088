// React imports
import { useState, useEffect, useContext, createContext } from 'react';

// Context imports
import { usePolygonApi } from 'context/api/polygon';
import { usePropertyType } from 'context/filters/property';
import { useCategory } from 'context/filters/category';

const DemmandApiContext: React.Context<any> = createContext(null)

export const useDemmandApi = () => useContext(DemmandApiContext);

export const DemmandApiProvider = ({children}: any) => {
	const { polygonData } = usePolygonApi();
	const { businessTypeId, propertyTypeId } = usePropertyType();
	const { categoryId } = useCategory();
	
	const [ demmandData, setDemmandData ] = useState<any>(null);
	
	useEffect(() => {
	  const fetchData = async () => {
	  	const tempUrl = `
	    	${process.env.REACT_APP_API_URL}/
	    	hist_api
			?business_type_id=${businessTypeId}
			&property_type_id=${propertyTypeId}
	    `
	  	const url = tempUrl.replace(/\s/g, '');
	    const res = await fetch(url);
	    const receivedData = await res.json();
	    setDemmandData(receivedData);
	  }
	  polygonData && categoryId === 2 && fetchData();
	}, [ polygonData, categoryId, businessTypeId, propertyTypeId ]);

	return (
		<DemmandApiContext.Provider value={{ demmandData }}>
			{children}
		</DemmandApiContext.Provider>
	)
}

DemmandApiContext.displayName = "DemmandApiContext";