// App imports
import { Table } from './table';
import { LoadingImage } from 'components/utils/loading'

// Context imports
import { useDemmandApi } from 'context/api/imoveis/demmand';

export const Demmand = () => {
	const { demmandData } = useDemmandApi();
	
	return (
		<div className="sidebar-item-wrapper">
			<div className="sidebar-sub-title">
				Dormitórios-Suítes-Vagas (DSV)
			</div>
			{demmandData ? <Table data={demmandData}/> : <LoadingImage/>}
		</div>
	)
} 

Demmand.displayName="Demmand";