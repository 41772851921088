// React imports
import { useContext, createContext } from 'react';

// Context imports
import { useLinesApi } from 'context/api/imoveis/lines';
import { usePrices } from 'context/filters/prices';
 
const LinesLimitsContext: React.Context<any> = createContext(null);

export const useLinesLimits = () => {
	return (
		useContext(LinesLimitsContext)
	)
}

export const LinesLimitsProvider = ({children}: any) => {
	const { linesData } = useLinesApi();
	const { unitPrice } = usePrices();

	const getLineData = (key: any) => 
		linesData && linesData[`${key}_${unitPrice === "price" ? "price" : "unit_price"}`];

	const bottomLimit = getLineData("bottom_limit");
	const topLimit = getLineData("top_limit");
	const minLine = getLineData("min_line");
	const meanLine = getLineData("mean_line");
	const maxLine = getLineData("max_line")

	return (
		<LinesLimitsContext.Provider value={{
			bottomLimit, topLimit,
			minLine, meanLine, maxLine
		}}>
			{children}
		</LinesLimitsContext.Provider>
	)
}

LinesLimitsContext.displayName = "LinesLimitsContext";